import type { HTMLProps } from "react";

import type { UnitType, LayoutSpacing } from "./../../types";

import * as styles from "./Spacer.styles";

interface Props extends HTMLProps<HTMLDivElement> {
  /**
   * Specifies the width
   *
   * @default one
   */
  width?: UnitType;
  /**
   * Specifies the height
   *
   * @default none
   */
  height?: UnitType;
  /**
   * Type of html element the Spacer will be rendered as
   *
   * @default div
   */
  type?: LayoutSpacing;
}

/**
 * Documentation:
 * https://aviary.docs.fullscript.cloud/components/layout/Spacer
 */
const Spacer = ({ width = "one", height, type, ...rest }: Props) => {
  const css = [
    styles.spacer,
    width && styles.width[width],
    height && styles.height[height],
    (height || type) && styles.heightDisplay,
    type && styles.type[type],
  ];

  return <div css={css} data-testid="aviary-spacer" {...rest} />;
};

export { Spacer };
