import { useTheme } from "@emotion/react";
import { faArrowUpRightFromSquare } from "@fortawesome/pro-regular-svg-icons";
import { colorProfileMapper, type AviaryColors } from "aviary-tokens";
import type { HTMLProps, Ref, ReactNode } from "react";

import { FontAwesomeIcon } from "@shared/react-fontawesome";
import type { LinkProps } from "@shared/react-router-dom";
import { Link as RouterLink } from "@shared/react-router-dom";

import { NoStyleButton } from "../Button/NoStyleButton";

import * as styles from "./Link.styles";

type BasicLinkProps = Partial<Pick<LinkProps, "to" | "replace" | "prefetch" | "state">>;

interface AviaryLinkProps<T = HTMLButtonElement> extends BasicLinkProps, HTMLProps<T> {
  /**
   * Adds in an underline to the link
   *
   * @default true
   */
  isUnderLined?: boolean;
  /**
   * Adds in an underline to the link on hover
   *
   * @default true
   */
  isHoverUnderLined?: boolean;
  /**
   * Turns the link light to be used on dark
   * backgrounds
   *
   * @default false
   */
  isLight?: boolean;
  /**
   * Turns isLight links to a muted color to be
   * less prominent
   *
   * @default false
   */
  isMuted?: boolean;
  /**
   * Disable the display of the external icon
   *
   * @default false
   */
  noExternalIcon?: boolean;
  /**
   * Option to disable the link
   */
  disabled?: boolean;
  /**
   * Custom ref attribute used to overcome
   * Link/Button ref type issues
   */
  innerRef?: Ref<any>;
  /**
   * Detemines the color intention.
   *
   * @default "success"
   */
  isColor?: Extract<AviaryColors, "success" | "system" | "danger" | "warning" | "highlight">;
  /**
   * Link content
   *
   * @default undefined
   */
  children?: ReactNode;
}

export type ButtonOrLinkType = AviaryLinkProps<HTMLButtonElement | HTMLAnchorElement>;

/**
 * Documentation:
 * https://aviary.docs.fullscript.cloud/components/navigation/Link
 */
const Link = ({
  children,
  href,
  type,
  isLight,
  isMuted,
  to,
  state,
  replace,
  innerRef,
  isUnderLined = true,
  isHoverUnderLined = false,
  noExternalIcon,
  isColor = "success",
  disabled = false,
  ...rest
}: ButtonOrLinkType) => {
  const currentTheme = useTheme();
  const themeColors = colorProfileMapper(currentTheme);

  const linkStyles = [
    styles.linkStyling,
    !isLight && styles.themedColor(themeColors[isColor]),
    disabled && styles.disabled,
    isLight && styles.light,
    isLight && isMuted && styles.lightMuted,
    !isUnderLined && styles.noUnderline,
    isHoverUnderLined && styles.hoverUnderline,
    !to && !href && styles.noStyleButton,
  ];

  const isExternal = () => {
    if (href) {
      return href.includes("www") || href.includes("http");
    }
  };

  const getValidButtonType = () => {
    if (type === "submit" || type === "reset") {
      return type;
    } else {
      return "button";
    }
  };

  // Using a button instead of an anchor tag with a role of button here
  // Adding `role="button"` tells screen readers that it's a button, but does not provide button functionality and is not keyboard accessible
  // https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/button_role
  const renderNoStyleButton = () => (
    <NoStyleButton
      css={linkStyles}
      {...rest}
      disabled={disabled}
      aria-disabled={disabled}
      ref={innerRef}
      type={getValidButtonType()}
    >
      {children}
    </NoStyleButton>
  );

  const options = {
    ...(isExternal() && { target: "_blank", rel: "noreferrer noopener" }),
  };

  const renderTargetLink = target => (disabled ? null : target);

  const renderAnchor = () => (
    <a
      href={renderTargetLink(href)}
      css={linkStyles}
      aria-disabled={disabled}
      role={disabled ? "link" : undefined}
      {...options}
      {...(rest as HTMLProps<HTMLAnchorElement>)}
      ref={innerRef}
    >
      {children}
      {!noExternalIcon && isExternal() && (
        <FontAwesomeIcon css={styles.externalIcon} icon={faArrowUpRightFromSquare} />
      )}
    </a>
  );

  const renderRouterLink = () => (
    <RouterLink
      to={renderTargetLink(to)}
      replace={replace}
      state={state}
      data-testid="router-link"
      css={linkStyles}
      aria-disabled={disabled}
      role={disabled ? "link" : undefined}
      {...(rest as HTMLProps<HTMLAnchorElement>)}
      ref={innerRef}
    >
      {children}
    </RouterLink>
  );

  const getElement = () => {
    if (!to && !href) {
      return renderNoStyleButton();
    } else if (to) {
      return renderRouterLink();
    }

    return renderAnchor();
  };

  return getElement();
};

export { Link, type AviaryLinkProps };
